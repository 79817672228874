// (c) 2023 384 (tm)

import { Interfaces } from 'src/snackabra/snackabra';
// import { SnackabraTypes } from '../index';
import { SBFileSystem } from '../file/system';

const DEBUG = true;
const DEBUG2 = false;

const sb384CacheName = 'sb384cache';

const navigatorObject = ('serviceWorker' in navigator) ? navigator : null;
if (DEBUG) console.log("[SBServiceWorker] navigatorObject: ", navigatorObject);

// let serviceWorkerFunctional = false;
// (window as any).serviceWorkerFunctional = serviceWorkerFunctional;

let serverPrefix: string = "<unknown>"
if (window.location) {
    serverPrefix = window.location.protocol + "//" + window.location.host
    if (DEBUG2) console.log("[SBServiceWorker] serverPrefix: ", serverPrefix);
}

// here is how we might prime it:

// // note that the actual data is in globalBufferMap.get(uniqueShardId)
// for (const key of this.finalFileList.keys()) {
//     let entry = this.finalFileList.get(key);
//     if (entry.type !== "directory") {
//         if (DEBUG2) console.log(`... kicking off cacheResource for ${key} (${entry.path + entry.name})`)
//         cacheResource(entry.path + entry.name, entry.uniqueShardId, entry.type, this.globalBufferMap);
//     }
// }

// console.log(navigator.serviceWorker);

export class SBServiceWorker {
    // sb384cachePromise: Promise<Cache | undefined>;
    #sb384cache: Cache | undefined;
    #sbfs: SBFileSystem;
    // serviceWorkerReadyPromise: Promise<void>;
    ready: Promise<boolean>;

    constructor(sbfs: SBFileSystem, messageHandler: (event: MessageEvent) => void) {
        this.#sbfs = sbfs;
        if (DEBUG) console.warn(`[SBServiceWorker] [constructor] ++++ setting up file helper service worker (${serverPrefix}) `)
        if (DEBUG2) console.log("[SBServiceWorker] [constructor ++++ SBFS:", this.#sbfs);
        this.ready = new Promise((resolve, reject) => {
            caches.open(sb384CacheName)
            .then((cache) => {
                this.#sb384cache = cache;
                this.setupServiceWorker(messageHandler)
                    .then(() => {
                        if (DEBUG) console.log("[SBServiceWorker] ++++ service worker setup complete")
                        resolve(true);
                    })
                    .catch((err) => {
                        console.error("[SBServiceWorker] Error setting up service worker: " + err)
                        reject(false);
                    })
            })
            .catch((err) => {
                console.error("[SBServiceWorker] Error opening cache: " + err)
                reject(false);
            })
        });
    }

    postMessage(message: any) {
        this.ready.then(() => {
            if ((!this.ready) || (!navigatorObject) ) {
                const msg = "[SBServiceWorker] 'ready' is null or false (?) or no navigatorObject ... cannot post any messages"
                console.error(msg)
                throw new Error(msg);
            }
            if ((navigatorObject.serviceWorker) && (navigatorObject.serviceWorker.controller)) {
                navigatorObject.serviceWorker.controller.postMessage(message);
            } else {
                const msg = '[SBServiceWorker] Service worker is not active or not ready, or no controlling service worker (?)';
                console.error(msg);
                throw new Error(msg);
            }
        });
    }

    // note that this makes sure there is only ONE service worker at the end of this;
    // if there was one to start, then, it will be "reused"
    async setupServiceWorker(messageHandler: (event: MessageEvent) => void): Promise<void> {
        if (!navigatorObject) {
            console.error("[SBServiceWorker] ERROR: navigator.serviceWorker is not available")
            return Promise.reject("[SBServiceWorker] ERROR: navigator.serviceWorker is not available");
        }
        try {
            const setOfRegistrations = await navigatorObject.serviceWorker.getRegistrations()
            if (setOfRegistrations.length > 1) {
                console.error("[devLoader] ERROR: we should never have MANY service workers registered")
                for (let registration of setOfRegistrations) {
                    console.log("[devLoader] ++++ unregistering service worker: ", registration)
                    await registration.unregister();
                }
                if (DEBUG) console.log('[SBServiceWorker] ++++ ... finished unregistering, registering a fresh one');
                await navigatorObject.serviceWorker.register('service-worker.js');
            } else if (setOfRegistrations.length === 1) {
                if (DEBUG) console.log("[devLoader] ++++ we already have a service worker registered")
                // send a message to our (single) controlling service worker
                if (!navigatorObject.serviceWorker.controller) {
                    console.error("[devLoader] ERROR: we have a single registered service worker, but no controller?")
                } else {
                    if (DEBUG) console.log("[devLoader] ++++ we have an EXISTING service worker")
                }
            } else {
                if (DEBUG) console.log('[SBServiceWorker] ++++ Did not have a service worker, registering one');
                await navigatorObject.serviceWorker.register('service-worker.js');
            }
            if (DEBUG) console.log('[SBServiceWorker] ++++ waiting for service worker to be ready then setting up message handler');
            await navigatorObject.serviceWorker.ready;
            navigatorObject.serviceWorker.addEventListener('message', messageHandler);
            await navigatorObject.serviceWorker.ready;
        } catch (e) {
            console.error("[SBServiceWorker] Error registering service worker: " + e);
        }
    }

    // ToDo: alternatively we should be messaging the service worker with the
    //       metadata and then have the service worker fetch the data from the
    //       server (and cache it) only upon request. perhaps that should be a
    //       separate interface (eg cacheResourceDeferred());


    async cacheResourceFromArrayBuffer(fileName: string, mimeType: string, arrayBuffer: ArrayBuffer): Promise<void> {
        if (!arrayBuffer || !(arrayBuffer instanceof ArrayBuffer)) {
            const msg = `[SBServiceWorker] Got empty or no data or not an array buffer for cacheResource()`
            console.error(msg)
            return Promise.reject(msg);
        }
        await this.ready;
        if ((!this.ready) || (!this.#sb384cache)) {
            const msg = "[SBServiceWorker] 'ready' or 'sb384cache' is null ... cannot cache any resources"
            console.error(msg)
            return Promise.reject(msg);
        }
        if (fileName === "/index.html") {
            if (DEBUG) console.log("[SBServiceWorker] **** automatically adding '/' for '/index.html'")
            await this.cacheResourceFromArrayBuffer("/", mimeType, arrayBuffer);
        }
        
        if (DEBUG) console.log(`[SBServiceWorker] Got data for ${fileName} cacheResourceFromArrayBuffer()`, arrayBuffer);

        // create Response to the cache using the file name as the key
        const response = new Response(arrayBuffer, { status: 200, headers: { 'Content-Type': mimeType } });
        await this.#sb384cache!.put(fileName, response);

        // Verify that the response is now in the cache - ToDo: can probably optimize and not block
        const cachedResponse = await this.#sb384cache!.match(fileName);
        if (cachedResponse) {
            if (DEBUG2) console.log('Response successfully cached:', cachedResponse);
        } else {
            console.error(`**** Response was not cached **** '${fileName}'`, response);
        }
    }

    async cacheResourceFromHandle(fileName: string, mimeType: string, handle: Interfaces.SBObjectHandle): Promise<void> {
        if (DEBUG) console.log(`[SBServiceWorker] Caching resource '${fileName}' mimeType '${mimeType}' from handle:`, handle);
        this.#sbfs.server.storage.fetchData(handle)
        .then(async (arrayBuffer) => {
            return this.cacheResourceFromArrayBuffer(fileName, mimeType, arrayBuffer);
        })
        .catch((err) => {
            const msg = `[SBServiceWorker] Error fetching data for handle ${handle}: ${err}`
            console.error(msg)
            return Promise.reject(msg);
        });
    }
}



        // this.#sbfs.server.storage.fetchData(handle)
        //     .then(async (arrayBuffer) => {
        //         // Create a Response object with the ArrayBuffer and MIME type
        //         const response = new Response(arrayBuffer, {
        //             status: 200, // this part seems to be browser/OS dependent
        //             headers: { 'Content-Type': mimeType },
        //         });
        //         // Add the Response to the cache using the file name as the key
        //         await this.#sb384cache!.put(fileName, response);
        //         // Verify that the response is now in the cache
        //         const cachedResponse = await this.#sb384cache!.match(fileName);
        //         if (cachedResponse) {
        //             if (DEBUG2) console.log('Response successfully cached:', cachedResponse);
        //         } else {
        //             console.error(`**** Response was not cached **** '${fileName}'`, response);
        //         }
        //     })
        //     .catch((err) => {
        //         console.error(`[SBServiceWorker] Error fetching data for handle ${handle}: ${err}`)
        //     });

        
    // // older approach, when being tested from inside multifile handler (where a globalbuffer map was available)
    // async cacheResource(fileName: string, uniqueShardId: string, mimeType: string, bufferMap: Map<any, any>): Promise<void> {
    //     if (!serviceWorkerFunctional) {
    //         console.error("service worker is not operational")
    //         return Promise.resolve();
    //     }
    //     if (fileName === "/service-worker.js" /* fileName.endsWith("service-worker.js") */) {
    //         console.log("**** special override: self-virtualizing service worker (/service-worker.js)")
    //         return Promise.resolve();
    //     }
    //     if (fileName === "/index.html") {
    //         console.log("**** special override: index.html can also be accessed as '/'")
    //         await this.cacheResource("/", uniqueShardId, mimeType, bufferMap);
    //     }
    //     if (DEBUG) console.log(`Caching resource '${fileName}' with uniqueShardId '${uniqueShardId}' and mimeType '${mimeType}'`);
    //     const cache = (await this.sb384cachePromise);
    //     let arrayBuffer = bufferMap.get(uniqueShardId);

    //     // Create a Response object with the ArrayBuffer and MIME type
    //     const response = new Response(arrayBuffer, {
    //         status: 200, // this part seems to be browser/OS dependent
    //         headers: { 'Content-Type': mimeType },
    //     });
    //     // Add the Response to the cache using the file name as the key
    //     await cache!.put(fileName, response);
    // }