// (c) 2023 384 (tm)

import { simpleBase64ToArrayBuffer } from './simpleB64';
import { base64ToArrayBuffer, arrayBufferToBase64 } from './b64';

import {
    base62ToArrayBuffer32, arrayBuffer32ToBase62, base62ToBase64, base64ToBase62, isBase62Encoded,
    assemblePayload, extractPayload
} from '../snackabra/snackabra';

export const utils = {
    simpleBase64ToArrayBuffer,
    base64ToArrayBuffer,
    arrayBufferToBase64,
    base62ToArrayBuffer32, arrayBuffer32ToBase62, base62ToBase64, base64ToBase62, isBase62Encoded,
    assemblePayload, extractPayload
};
