// (c) 2023 384 (tm)

const reverse: number[] = []
const B64_CODE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
for (let i = 0; i < B64_CODE.length; i++) reverse[B64_CODE.charCodeAt(i)] = i
reverse['-'.charCodeAt(0)] = 62 // minus
reverse['_'.charCodeAt(0)] = 63 // underscore
const simple_b64_regex = /^([A-Za-z0-9+/_\-]{4})*$/ // tolerant of chars, multiple of 24 bits, no padding

/**
 * Fast base64 to array buffer conversion for 'simple' b64 strings
 * (Multiple of 24 bits, no padding, no whitespace)
 */
export function simpleBase64ToArrayBuffer(base64: string) {
  // make sure it matches regex
  if (!simple_b64_regex.test(base64))
    throw new TypeError(`Invalid ('simple') base64 string - must match '${simple_b64_regex}'`)
  const buffer = new ArrayBuffer(base64.length * 3 / 4 + 1)
  const view = new DataView(buffer)
  for (let i = base64.length - 4, j = buffer.byteLength - 4; i >= 0; i -= 4, j -= 3) {
    const a = reverse[base64.charCodeAt(i)]
    const b = reverse[base64.charCodeAt(i + 1)]
    const c = reverse[base64.charCodeAt(i + 2)]
    const d = reverse[base64.charCodeAt(i + 3)]
    view.setUint32(j, (a << 18) | (b << 12) | (c << 6) | d)
  }
  return new Uint8Array(buffer, 1)
}