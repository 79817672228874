// (c) 2023 384 (tm)

import {
    Channel,
    SBMessage,
    Snackabra,
    SBCrypto,
    SB384,
    version,
    ChannelEndpoint,
    ChannelSocket,
    arrayBufferToBase64,
    compareBuffers
} from './snackabra';

export const NewSB = {
    Channel,
    SBMessage,
    Snackabra,
    SBCrypto,
    SB384,
    version,
    ChannelEndpoint,
    ChannelSocket,
    arrayBufferToBase64,
    compareBuffers
}
