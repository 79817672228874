import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import PageNotFound from "./errors/PageNotFound";
import Home from "./Home";
import { BrandingProvider } from "../contexts/BrandingContext";


const AppRoutes = () => {
    return (
        <BrandingProvider>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route path="/404" element={<PageNotFound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Router>
        </BrandingProvider>
    );
};

export default AppRoutes;
