// (c) 2023 384 (tm)

// primarily creates class FileHelper, which is used by the UI code
// to parse any files or directories that have been selected

import { arrayBuffer32ToBase62 } from 'src/snackabra/snackabra';
import { crypto } from '../crypto/index';

// SUBTLE note about this code:
// we try hard to execute synchronously (against our nature), because
// the order in which bits and pieces of information arrive is important.
// for example, we try to process a directory before it's contents.


// TODO ... working on it, this file should not have ANY snackabra references
// import { Snackabra, SBServer } from "../snackabra/snackabra";
// import type { SBChannelId, Interfaces } from "../snackabra/snackabra"

// TODO
// in many circumstances, we can infer directory structure from 
// the various sources of information.  doing this reliably
// (for example handling "dangling" directories) is tricky to
// get right. so for now we only include the structure that
// is clearly indicated by the files.  instead of removing the
// partial code, however, we just disable it:

const SKIP_DIR = true;  // if you turn this false, you have work to do 

const DEBUG = false;
const DEBUG2 = false; // more verbose
const DEBUG3 = false; // etc

export const version = "0.0.19";
if (DEBUG) console.warn("==== SBFileHelper.ts v" + version + " loaded ====")

//#region HELPER FUNCTIONS ************************************************************************************************

// helper function to pull properties of interest out, resilient
// to what is available on the object/class/whatever
// const fileInfo = { ...getProperties(fileObject, propertyList) };

function getProperties(obj: any, propertyList: Array<string>) {
    const properties: { [key: string]: any } = {};
    // First priority: regular properties (directly on the object)
    propertyList.forEach((property) => {
        if (obj.hasOwnProperty(property)) {
            properties[property] = obj[property];
        }
    });
    // Second priority: own properties (from Object.getOwnPropertyNames)
    Object.getOwnPropertyNames(obj).forEach((property) => {
        if (propertyList.includes(property) && !properties.hasOwnProperty(property)) {
            properties[property] = obj[property];
        }
    });
    // Third priority: properties up the prototype chain (from for...in loop)
    for (const property in obj) {
        if (propertyList.includes(property) && !properties.hasOwnProperty(property)) {
            properties[property] = obj[property];
        }
    }
    return properties;
}

function getMimeType(fileName: string): string {
    // Mapping of file extensions to MIME types
    const MIME_TYPES: Record<string, string> = {
        '.aac': 'audio/aac',   // AAC audio
        '.abw': 'application/x-abiword',   // AbiWord document
        '.arc': 'application/x-freearc',   // Archive document (multiple files embedded)
        '.avif': 'image/avif',   // AVIF image
        '.avi': 'video/x-msvideo',   // AVI: Audio Video Interleave
        '.azw': 'application/vnd.amazon.ebook',   // Amazon Kindle eBook format
        '.bin': 'application/octet-stream',   // Any kind of binary data
        '.bmp': 'image/bmp',   // Windows OS/2 Bitmap Graphics
        '.bz': 'application/x-bzip',   // BZip archive
        '.bz2': 'application/x-bzip2',   // BZip2 archive
        '.cda': 'application/x-cdf',   // CD audio
        '.csh': 'application/x-csh',   // C-Shell script
        '.css': 'text/css',   // Cascading Style Sheets (CSS)
        '.csv': 'text/csv',   // Comma-separated values (CSV)
        '.doc': 'application/msword',   // Microsoft Word
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',   // Microsoft Word (OpenXML)
        '.eot': 'application/vnd.ms-fontobject',   // MS Embedded OpenType fonts
        '.epub': 'application/epub+zip',   // Electronic publication (EPUB)
        '.gz': 'application/gzip',   // GZip Compressed Archive
        '.gif': 'image/gif',   // Graphics Interchange Format (GIF)
        '.htm': 'text/html',   // HyperText Markup Language (HTML)
        '.html': 'text/html',   // HyperText Markup Language (HTML)
        '.ico': 'image/vnd.microsoft.icon',   // Icon format
        '.ics': 'text/calendar',   // iCalendar format
        '.jar': 'application/java-archive',   // Java Archive (JAR)
        '.jpeg': 'image/jpeg',   // JPEG images
        '.jpg': 'image/jpeg',   // JPEG images
        '.js': 'text/javascript (Specifications: HTML and RFC 9239)',   // JavaScript
        '.json': 'application/json',   // JSON format
        '.jsonld': 'application/ld+json',   // JSON-LD format
        '.mid': 'audio/midi',   // Musical Instrument Digital Interface (MIDI)
        '.midi': 'audio/midi',   // Musical Instrument Digital Interface (MIDI)
        '.mjs': 'text/javascript',   // JavaScript module
        '.mp3': 'audio/mpeg',   // MP3 audio
        '.mp4': 'video/mp4',   // MP4 video
        '.mpeg': 'video/mpeg',   // MPEG Video
        '.mpkg': 'application/vnd.apple.installer+xml',   // Apple Installer Package
        '.odp': 'application/vnd.oasis.opendocument.presentation',   // OpenDocument presentation document
        '.ods': 'application/vnd.oasis.opendocument.spreadsheet',   // OpenDocument spreadsheet document
        '.odt': 'application/vnd.oasis.opendocument.text',   // OpenDocument text document
        '.oga': 'audio/ogg',   // OGG audio
        '.ogv': 'video/ogg',   // OGG video
        '.ogx': 'application/ogg',   // OGG
        '.opus': 'audio/opus',   // Opus audio
        '.otf': 'font/otf',   // OpenType font
        '.png': 'image/png',   // Portable Network Graphics
        '.pdf': 'application/pdf',   // Adobe Portable Document Format (PDF)
        '.php': 'application/x-httpd-php',   // Hypertext Preprocessor (Personal Home Page)
        '.ppt': 'application/vnd.ms-powerpoint',   // Microsoft PowerPoint
        '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',   // Microsoft PowerPoint (OpenXML)
        '.rar': 'application/vnd.rar',   // RAR archive
        '.rtf': 'application/rtf',   // Rich Text Format (RTF)
        '.sh': 'application/x-sh',   // Bourne shell script
        '.svg': 'image/svg+xml',   // Scalable Vector Graphics (SVG)
        '.tar': 'application/x-tar',   // Tape Archive (TAR)
        '.tif': 'image/tiff',   // Tagged Image File Format (TIFF)
        '.tiff': 'image/tiff',   // Tagged Image File Format (TIFF)
        '.ts': 'video/mp2t',   // MPEG transport stream
        '.ttf': 'font/ttf',   // TrueType Font
        '.txt': 'text/plain',   // Text, (generally ASCII or ISO 8859-n)
        '.vsd': 'application/vnd.visio',   // Microsoft Visio
        '.wav': 'audio/wav',   // Waveform Audio Format
        '.weba': 'audio/webm',   // WEBM audio
        '.webm': 'video/webm',   // WEBM video
        '.webp': 'image/webp',   // WEBP image
        '.woff': 'font/woff',   // Web Open Font Format (WOFF)
        '.woff2': 'font/woff2',   // Web Open Font Format (WOFF)
        '.xhtml': 'application/xhtml+xml',   // XHTML
        '.xls': 'application/vnd.ms-excel',   // Microsoft Excel
        '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',   // Microsoft Excel (OpenXML)
        '.xml': 'application/xml',   // XML
        '.xul': 'application/vnd.mozilla.xul+xml',   // XUL
        '.zip': 'application/zip',   // ZIP archive
        '.7z': 'application/x-7z-compressed',   // 7-zip archive
    };

    // Extract the file extension from the file name
    const fileExtension = fileName.slice(fileName.lastIndexOf('.'));

    // Return the MIME type if it exists in the mapping, or an empty string otherwise
    return MIME_TYPES[fileExtension];
}
//#endregion HELPER FUNCTIONS ************************************************************************************************

//#region TYPESCRIPT TYPES ETC ************************************************************************************************

// there are a few weird things we need to do, because some browser behavior is 
// not captured by standard typescript headers etc.  for example, for some
// reason ... Microsoft header files don't support some stuff Apple uses ...

// FileEntry is non standard ... so we need to have some definitions 
interface Entry {
    isFile: boolean;
    isDirectory: boolean;
    name: string;
    fullPath: string;
    filesystem: FileSystem;
    getMetadata(successCallback: MetadataCallback, errorCallback?: ErrorCallback): void;
}
interface FileSystemFileEntry extends Entry {
    isFile: true;
    isDirectory: false;
    file(successCallback: FileCallback, errorCallback?: ErrorCallback): void;
}


interface Metadata {
    modificationTime: Date;
    size: number;
}
type MetadataCallback = (metadata: Metadata) => void;
type ErrorCallback = (error: DOMException) => void;
type FileCallback = (file: File) => void;

interface CustomEventTarget extends EventTarget {
    files?: FileList;
    items?: DataTransferItemList;
}

export interface SBFileMetaData {
    name?: string;
    fullPath?: string;
    size?: number;
    type?: string;
    file?: (successCallback: FileCallback, errorCallback?: ErrorCallback) => void;
    lastModified?: number;
    lastModifiedDate?: Date;
    webkitRelativePath?: string;
    isDirectory?: boolean;
    isFile?: boolean;
    getMetaDataName?: string;
    getMetaDataSize?: number;
    getMetaDataType?: string;
    getMetaDataLastModified?: number;
    getMetaDataGetFileError?: any;
    getMetaDataModificationTime?: Date;
    getMetaDataFile?: File;
    getMetaDataError?: string;
    noGetMetaData?: boolean;
}

//#endregion TYPESCRIPT TYPES ETC ************************************************************************************************

// these are the properties that we (potenially) care about
const propertyList = ['lastModified', 'name', 'type', 'size', 'webkitRelativePath', 'fullPath', 'isDirectory', 'isFile',
    'SBitemNumber', 'SBitemNumberList', 'fileContentCandidates', 'fileContents', 'uniqueShardId',
    'SBparentEntry', 'SBparentNumber', 'SBfoundMetaData', 'SBfullName'];

(window as any).SBFileHelperReady = new Promise((resolve, reject) => {
    (window as any).SBFileHelperReadyResolve = resolve;
    (window as any).SBFileHelperReadyReject = reject;
});

(window as any).SBFileHelperReadyResolve()

export const SBFileHelperReady = (window as any).SBFileHelperReady


// internal debug function, used to verify files can be accessed
function testToRead(file: File | FileSystemEntry | FileSystemFileEntry, location: string) {
    try {
        const reader = new FileReader();
        reader.readAsText(file as File);
        reader.onload = (e) => {
            if (DEBUG2) {
                console.log("========================================================")
                console.log(`[${location}] was able to readAsText():`);
                console.log(file)
            }
            if (e.target === null) {
                if (DEBUG) console.log('**** e.target is null ****');
            } else {
                if (DEBUG2) console.log(`[${location}] (direct) successfully read file ${file.name}`);
            }
        }
    } catch (error) {
        try {
            if ((file as any).file) {
                let originalFile = file;
                (file as any).file((file: File) => {
                    if (DEBUG2) {
                        console.log("========================================================")
                        console.log(`[${location}] was able to get a file() for object:`);
                        console.log(originalFile)
                        console.log(file)
                    }
                    const reader = new FileReader();
                    reader.readAsText(file as File);
                    reader.onload = (e) => {
                        if (e.target === null) {
                            console.log('**** e.target is null ****');
                        } else {
                            if (DEBUG2) console.log(`[${location}] (using file()) successfully read file ${file.name}`);
                            // console.log(e.target.result);
                        }
                    }
                });
            }
        } catch (error) {
            console.log(`[${location}] error reading file ${file.name}`);
        }
    }
}

// Global counter utility; works well with async/await etc
const createCounter = () => {
    let counter = 0;
    const inc = async (): Promise<number> => {
        await new Promise((resolve) => setTimeout(resolve, 0)); // Simulate asynchronous operation
        counter++;
        return counter - 1; // we count starting at zero
    };
    return { inc };
};

let printedWarning = false;
export function printWarning() {
    if (!printedWarning) {
        console.log("================================================")
        console.log("Warning: you are running in 'local web page' mode")
        console.log("on a browser that has some restrictions.");
        console.log("");
        console.log("So far, looks like this browser will not let you");
        console.log("navigate *into* directories that are drag-and-dropped");
        console.log("Might also be having issues getting meta data,");
        console.log("as well as getting the 'full' path of the file.");
        console.log("============================================")
        printedWarning = true;
    }
    if ((window as any).directoryDropText)
        (window as any).directoryDropText!.innerHTML = "Click to choose directories<br />(drag and drop might not work))";

}

/*
 * This class is used by the UI code to parse any files or directories that have been selected
 * by the UI, whether through a file input or a drag-and-drop operation
 * 
 * The key data structures to access are:
 * 
 * finalFileList: a map of all files that have been processed
 * globalBufferMap: a map of all array buffers that have been read ("seen")
 * 
 * These are accumulative and do not reset on any UI interaction that this
 * class can see, they need to be explicitly cleared by any application.
 * (For example after uploading a set)
 * 
 */
export class FileHelper {
    // server: Snackabra;

    // todo: perhaps from configuration?
    #ignoreFileSet = new Set()

    // give any file or item "seen" a unique number (reset on every UI interaction)
    #globalItemNumber = createCounter();

    // if there are items, files will at first be numbered the same (reset on every UI interaction)
    #globalFileItemNumber = createCounter();

    // all of our scanning results go here, unabridged (reset on every UI interaction)
    #globalFileMap = new Map();

    // this is the distilled list of files we will add to finalFileList (reset on every UI interaction)
    #currentFileList = new Map();

    // this is one accumulative, and used directly for the table (NOT reset)
    finalFileList = new Map();

    // track all (unique) array buffers that have been read (NOT reset)
    // TODO: strictly speaking we don't do garbage collection on this
    globalBufferMap = new Map();

    constructor() {
        // add some files to ignore
        this.#ignoreFileSet.add(".DS_Store");
        this.#ignoreFileSet.add("/.DS_Store");
        // add a regex to catch emacs backup files
        this.#ignoreFileSet.add(/.*~$/);
        // console.log(this)
    }

    ignoreFile(fileName: string): boolean {
        if (this.#ignoreFileSet.has(fileName)) return true;
        for (let ignoreFile of this.#ignoreFileSet)
            if (ignoreFile instanceof RegExp)
                if (ignoreFile.test(fileName))
                    return true;
        return false;
    }

    //#region SCAN ITEMS AND FILES ****************************************************************************************

    // these are called by the UI code to parse any files or directories that have been selected
    // by the UI, whether through a file input or a drag-and-drop operation

    // returns metadata for a file object whether it is a File or FileEntry
    extractFileMetadata(fileObject: File | FileSystemEntry | FileSystemFileEntry): Promise<SBFileMetaData> {
        function localResolve(metadata: SBFileMetaData): SBFileMetaData {
            // console.log("Extracted metadata:");
            // console.log(metadata);
            return metadata;
        }
        return new Promise<SBFileMetaData>((resolve) => {
            const metadata: SBFileMetaData = {} as SBFileMetaData;
            // console.log("Extracting metadata from object:");
            // console.log(fileObject);
            if (fileObject instanceof File) {
                if (fileObject.name)
                    metadata.name = fileObject.name;
                if (fileObject.size)
                    metadata.size = fileObject.size;
                if (fileObject.type)
                    metadata.type = fileObject.type;
                if (fileObject.lastModified)
                    metadata.lastModified = fileObject.lastModified;
                if (fileObject.webkitRelativePath)
                    metadata.webkitRelativePath = fileObject.webkitRelativePath;
            }
            if ((typeof FileSystemEntry !== "undefined") && (fileObject instanceof FileSystemEntry)) {
                if (fileObject.name)
                    metadata.name = fileObject.name;
                if (fileObject.fullPath)
                    metadata.fullPath = fileObject.fullPath;
                if (fileObject.isDirectory !== undefined)
                    metadata.isDirectory = fileObject.isDirectory;
                if (fileObject.isFile !== undefined)
                    metadata.isFile = fileObject.isFile;
                metadata.noGetMetaData = true;
            }
            if ((typeof FileSystemFileEntry !== "undefined") && (fileObject instanceof FileSystemFileEntry)) {
                if (fileObject.fullPath)
                    metadata.fullPath = fileObject.fullPath;
                // if it's there, not so important:
                // if (fileObject.lastModifiedDate)
                //     metadata.lastModifiedDate = fileObject.lastModifiedDate;
                if (fileObject.isDirectory !== undefined)
                    metadata.isDirectory = fileObject.isDirectory;
                if (fileObject.isFile !== undefined)
                    metadata.isFile = fileObject.isFile;
                if (fileObject.file)
                    metadata.file = fileObject.file;
            }
            if ((typeof FileSystemFileEntry !== "undefined") && ((fileObject instanceof FileSystemFileEntry))
                && ((fileObject as unknown as FileSystemFileEntry).getMetadata)) {
                // this is the only situation where we have another promise 
                (fileObject as unknown as FileSystemFileEntry).getMetadata((fileMetadata) => {
                    // console.log("Got meta data from file object:");
                    // console.log(fileMetadata);
                    // metadata.getMetaDataName = fileMetadata.name; // apparently not available?
                    metadata.getMetaDataSize = fileMetadata.size;
                    metadata.getMetaDataModificationTime = fileMetadata.modificationTime;
                    if (fileObject.file) fileObject.file((file) => {
                        metadata.getMetaDataFile = file;
                        metadata.getMetaDataType = file.type;
                        resolve(localResolve(metadata));
                    }, (error) => {
                        metadata.getMetaDataGetFileError = error;
                        resolve(localResolve(metadata));
                    });
                }, (error: any) => {
                    metadata.getMetaDataError = error;
                    resolve(localResolve(metadata));
                });
            } else {
                // otherwise, all info should be immediately available
                metadata.noGetMetaData = true;
                resolve(localResolve(metadata));
            }
        });
    }

    async scanFile(file: File | FileSystemEntry | FileSystemFileEntry, fromItem: number) {
        if (!file) return
        if (DEBUG2) testToRead(file, 'scanFile');
        if (this.ignoreFile(file.name)) return;

        let path: string;
        if (file instanceof File) {
            path = file.webkitRelativePath;
        } else if (file instanceof FileSystemEntry) {
            path = file.fullPath;
        } else if (file instanceof FileSystemFileEntry) {
            path = file.fullPath;
        } else {
            console.warn("**** Unknown file type (should not happen):");
            console.log(file);
            return;
        }

        let fileNumber = await (fromItem === -1 ? this.#globalFileItemNumber.inc() : fromItem);
        (file as any).SBitemNumber = fileNumber;

        let fromItemText = fromItem === -1 ? '' : ` (from item ${fromItem})`

        // fileListFile1_Files.push(file);

        await this.extractFileMetadata(file).then((metadata) => {
            if (DEBUG2) console.log(`adding ${fileNumber}`);
            (file as any).SBfoundMetaData = metadata

            // globalFileMap.set(`file ${fileNumber} (item ${fromItem}): ` + "/" + metadata.name + " [file] [2] (" + metadata.size + ")", file);
            // if ((file instanceof File) && (file.type !== "")) {
            //     globalFileMap.set(`file ${fileNumber} (item ${fromItem}): ` + "/" + metadata.name + " [meta from file]", metadata);
            // }

            if (path === '') {
                // fileListFile1.push('/' + file.name);
                this.#globalFileMap.set(`file ${fileNumber} ${fromItemText} name: '/` + file.name + "' ", file);
            } else {
                // fileListFile1.push('/' + path);
                this.#globalFileMap.set(`file ${fileNumber} ${fromItemText} path: '/` + path + "'", file);
            }

        }).catch((error) => {
            console.log("Error getting meta data for FILE (should NOT happen):")
            console.log(file)
            console.log(error);
        });
    }

    scanFileList(files: FileList | undefined) {
        if (!files) return;
        if (DEBUG) console.log(`==== scanFileList called, files.length: ${files.length}`);
        if (files)
            for (let i = 0; i < files.length; i++)
            /* await */ this.scanFile(files[i], -1);
    }

    async scanItem(item: FileSystemEntry | FileSystemFileEntry | null, parent: any) {
        if (!item) return;
        if (this.ignoreFile(item.name)) return;
        if (DEBUG2) testToRead(item, 'scanItem');

        let itemNumber = await this.#globalItemNumber.inc();

        if (DEBUG2) { console.log(`scanItem ${itemNumber} ${item.name}`); console.log(item); }

        let parentString = '';
        (item as any).SBitemNumber = itemNumber;
        if (parent !== null) {
            (item as any).SBparentEntry = parent;
            (item as any).SBparentNumber = parent.SBitemNumber;
            parentString = ` (parent ${parent.SBitemNumber}) `;
            if (!parent.SBfullName)
                // if we're a child then parent must be a parent
                parent.SBfullName = parent.name;
            // only if parents are around do we assert any knowledge of path
            (item as any).SBfullName = parent.SBfullName + "/" + item.name;
        }

        // if (item.fullPath)
        //     globalFileMap.set(`item ${itemNumber}: ` + item.fullPath + ` [item] [0] - indent ${indent}`, item);

        // globalFileMap.set(`item ${itemNumber}: ` + '/' + item.name + ` [item] [1] - indent ${indent}`, item);

        await this.extractFileMetadata(item).then((metadata) => {
            (item as any).SBfoundMetaData = metadata
            // globalFileMap.set(`item ${itemNumber}: ` + item.fullPath + ` [item] [2] - indent ${indent} `, item);
            // globalFileMap.set(`item ${itemNumber}: ` + item.fullPath + ` [meta from item] - indent ${indent} `, metadata);
        }).catch((error) => {
            console.log("Error getting meta data for ITEM (should not happen):")
            console.log(item)
            console.log(error);
        });

        if (item.isDirectory) {
            const myThis = this; // workaround (VS issue?)
            let directoryReader = (item as unknown as FileSystemDirectoryEntry).createReader();
            (item as any).SBdirectoryReader = directoryReader;
            this.#globalFileMap.set(`item ${itemNumber}: '/` + item.name + `' [directory] ${parentString}`, item);
            directoryReader.readEntries(function (entries) {
                entries.forEach(async function (entry) {
                    await myThis.scanItem(entry, item);
                });
            }, function (error: any) {
                printWarning();
                if (DEBUG) console.log(`Browser restriction: Unable to process this item as directory, '${item.name}':`);
                if (DEBUG2) console.log(error)
            });
        } else {
            this.#globalFileMap.set(`item ${itemNumber}: '/` + item.name + "' " + parentString, item);
            (item as FileSystemFileEntry).file((file) => {
                this.scanFile(file, itemNumber);
            }, function () {
                printWarning();
            });
        }

    }

    scanItemList(items: DataTransferItemList | undefined) {
        if (!items) return;
        if (DEBUG) console.log(`==== scanItemList called, items.length: ${items.length}`);
        // console.log(items);
        for (let i = 0; i < items.length; i++) {
            let item = items[i].webkitGetAsEntry();
            if (item) /* await */ this.scanItem(item, null);
            else { console.log("just FYI, not a file/webkit entry:"); console.log(items[i]); }
        }
    }
    //#endregion SCAN ITEMS OR FILES *******************************************************************************************************



    // called after every user interaction (eg any possible additions of files)
    afterOperation(callback: (table: any[]) => void) {
        setTimeout(() => {
            (async () => {
                console.log("-------DONE building globalFileMap---------")
                console.log(this.#globalFileMap);

                let nameToFullPath = new Map<string, string>();

                let candidateFileList = new Map();

                this.#globalFileMap.forEach((value, _key) => {
                    if (!this.ignoreFile(value.name)) {
                        if (DEBUG2) { console.log(`[${value.name}] Processing global file map entry: `); console.log(value); }
                        if (value.SBitemNumber !== undefined) {
                            let currentInfo = candidateFileList.get(value.SBitemNumber);
                            if (currentInfo) {
                                // let altFullPath = value.fullPath;
                                // let altFileContentCandidates = value.fileContentCandidates;
                                let newInfo = getProperties(value, propertyList);
                                // Object.assign(currentInfo, getProperties(value, propertyList));
                                Object.assign(newInfo, currentInfo);
                                if ((value.fullPath) && ((!newInfo.fullPath) || (value.fullPath.length > newInfo.fullPath.length)))
                                    newInfo.fullPath = value.fullPath;
                                newInfo.fileContentCandidates.push(value);
                                // currentInfo.fileContentCandidates = altFileContentCandidates;
                                candidateFileList.set(value.SBitemNumber, newInfo);
                            } else {
                                candidateFileList.set(value.SBitemNumber, Object.assign({}, getProperties(value, propertyList)));
                                currentInfo = candidateFileList.get(value.SBitemNumber);
                                currentInfo.fileContentCandidates = [value];
                            }
                        } else if (value.fullPath) {
                            // in some cases we can pick up path from here
                            if (DEBUG2) {
                                console.log(`++++ adding path info for '${value.name}':`);
                                console.log(value.fullPath);
                                console.log(value)
                            }
                            nameToFullPath.set(value.name, value.fullPath);
                        } else {
                            if (DEBUG2) {
                                console.log(`++++ ignoring file '${value.name}' in first phase (SHOULD NOT HAPPEN)`);
                                console.log(value);
                            }
                        }
                    } else {
                        if (DEBUG2) console.log(`Ignoring file '${value.name}' (based on ignoreFile)`);
                    }
                });

                console.log("-------DONE building candidateFileList---------")
                console.log(candidateFileList);
                // now merge into currentFileList
                candidateFileList.forEach((value, key) => {
                    if ((value.SBfullName !== undefined) && (("/" + value.SBfullName) !== value.fullPath)) {
                        console.log("WARNING: SBfullName and fullPath/name do not match");
                        console.log(`Name: ${value.name}, fullPath: ${value.fullPath}, SBfullName: ${value.SBfullName}`);
                        console.log(value)
                    }
                    // pullPath is not reliable in the absence of our ability to reconstruct from parent-child
                    let uniqueName = value.SBfullName || value.webkitRelativePath + '/' + value.name;
                    /* if ((value.isDirectory) && (SKIP_DIR)) {
                        if (DEBUG) console.log(`Skipping directory '${uniqueName}'`);
                    } else */ if (uniqueName !== undefined) {
                        if (value.isDirectory === true) {
                            uniqueName += " [directory]";
                        } else if (value.isFile === true) {
                            uniqueName += " [file]";
                        }
                        if ((value.size !== undefined) && (value.isDirectory != true)) {
                            uniqueName += ` [${value.size} bytes]`;
                        }
                        if (value.lastModified !== undefined) {
                            uniqueName += ` [${value.lastModified}]`;
                        }
                        if (DEBUG2) {
                            console.log(`processing object ${key} unique name '${uniqueName}':`);
                            console.log(value)
                        }
                        let currentInfo = this.#currentFileList.get(uniqueName);
                        if (currentInfo) {
                            let altFullPath = currentInfo.fullPath;
                            let altFileContentCandidates = currentInfo.fileContentCandidates;
                            let altSbItemNumberList = currentInfo.SBitemNumberList;
                            Object.assign(currentInfo, getProperties(value, propertyList));
                            if ((altFullPath) && ((!currentInfo.fullPath) || (altFullPath.length > currentInfo.fullPath.length)))
                                currentInfo.fullPath = altFullPath;
                            if (altFileContentCandidates) {
                                if (currentInfo.fileContentCandidates === undefined) currentInfo.fileContentCandidates = [];
                                currentInfo.fileContentCandidates.push(...altFileContentCandidates);
                            }
                            altSbItemNumberList.push(value.SBitemNumber);
                            currentInfo.SBitemNumberList = altSbItemNumberList;
                        } else {
                            value.SBitemNumberList = [value.SBitemNumber];
                            this.#currentFileList.set(uniqueName, value);
                            currentInfo = candidateFileList.get(uniqueName);
                        }
                        if (DEBUG2) {
                            console.log(`... currentInfo for '${uniqueName}' (${uniqueName}):`);
                            console.log(currentInfo);
                        }
                    } else {
                        if (DEBUG) {
                            console.log(`++++ ignoring file - it's lacking fullPath (should be rare)`);
                            console.log(value);
                        }
                    }
                });

                console.log("-------DONE building currentFileList---------")
                console.log(this.#currentFileList)

                // we'll now try reading all the files, and gathering any missing metadata while we're at it

                // attempts to read a file, returns promise with contents, or null if not readable
                async function FP(file: File | FileSystemEntry | FileSystemFileEntry): Promise<ArrayBuffer | null> {
                    return new Promise(async (resolve) => {
                        try {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                if ((e.target === null) || (e.target.result === null)) {
                                    if (DEBUG2)
                                        console.log(`+++++++ got a null back for '${file.name}' (??)`);
                                    resolve(null)
                                } else if (typeof e.target.result === 'string') {
                                    if (DEBUG2)
                                        console.log(`+++++++ got a 'string' back for '${file.name}' (??)`);
                                    resolve(null)
                                } else {
                                    if (DEBUG2) {
                                        console.log(`+++++++ read file '${file.name}'`);
                                        console.log(e.target.result);
                                    }
                                    resolve(e.target.result)
                                }
                            }
                            reader.onerror = (event) => {
                                if (DEBUG2) { console.log(`Could not read: ${file.name}`); console.log(event); }
                                resolve(null);
                            }
                            // we try to release pressure on the browser
                            await new Promise((resolve) => setTimeout(resolve, 20));
                            reader.readAsArrayBuffer(file as File);
                        } catch (error) {
                            try {
                                if (DEBUG2) console.log(`+++++++ got error on '${file.name}', will try as FileSystemFileEntry`);
                                if ((file as any).file) {
                                    (file as any).file(async (file: File) => {
                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                            if ((e.target === null) || (e.target.result === null)) resolve(null)
                                            else if (typeof e.target.result === 'string') resolve(null)
                                            else resolve(e.target.result)
                                        }
                                        reader.onerror = () => { resolve(null); }
                                        // we try to release pressure on the browser
                                        await new Promise((resolve) => setTimeout(resolve, 20));
                                        reader.readAsArrayBuffer(file as File);
                                    });
                                } else {
                                    if (DEBUG2) console.log(`... cannot treat as file: ${file.name}`);
                                }
                            } catch (error) {
                                if (DEBUG2) console.log(`Could not read: ${file.name}`);
                            }
                            resolve(null);
                        }
                    });
                }

                async function findFirstResolved(fileList: Array<File | FileSystemEntry | FileSystemFileEntry>): Promise<ArrayBuffer | null> {
                    for (let index = 0; index < fileList.length; index++) {
                        let result = await FP(fileList[index]);
                        if (result !== null) return result;
                    }
                    if (DEBUG) {
                        console.log("findFirstResolved(): found nothing usable from this fileList")
                        console.log(fileList)
                    }
                    return null;
                }

                let listOfFilePromises: Array<Promise<void>> = [];
                this.#currentFileList.forEach((value, key) => {
                    if ((value.fileContentCandidates) && (!value.uniqueShardId)) {
                        // listOfFilePromises.push(value);
                        listOfFilePromises.push(
                            new Promise<void>(async (resolve) => {
                                findFirstResolved(value.fileContentCandidates)
                                    .then(async (result: ArrayBuffer | null) => {
                                        if (DEBUG3) console.log(`got response for ${value.name}`)
                                        if (!result) {
                                            if (DEBUG2) console.log(`... contents are empty for item ${key} (probably a directory)`)
                                            // value.uniqueShardId = null;  // actually no, we'll leave it as undefined
                                        } else {
                                            const { id_binary } = await crypto.sbCrypto.generateIdKey(result!)
                                            const id32 = arrayBuffer32ToBase62(id_binary);
                                            let alreadyThere = this.globalBufferMap.get(id32);
                                            if (alreadyThere) {
                                                if (DEBUG2) console.log(`... duplicate file found for ${key}`)
                                                result = alreadyThere; // memory conservation
                                            } else {
                                                this.globalBufferMap.set(id32, result);
                                            }
                                            if (value.size === undefined) {
                                                if (DEBUG2) console.log(`... setting size for ${key} to ${result!.byteLength}`)
                                                value.size = result!.byteLength;
                                            } else if (value.size !== result!.byteLength) {
                                                if (DEBUG) console.log(`WARNING: file ${value.name} has size ${value.size} but contents are ${result!.byteLength} bytes (ignoring)`)
                                                resolve(); // not the droid we're looking for
                                            }
                                            value.uniqueShardId = id32;
                                            if (DEBUG2) console.log(`... found contents for ${key} (${result!.byteLength} bytes)`)
                                        }
                                        resolve();
                                    })
                                    .catch((error: any) => {
                                        if (DEBUG2) console.log(`couldn't read anything for ${key}`, error);
                                        // value.uniqueShardId = null;
                                        resolve();
                                    });
                            })
                        );
                    } else { if (DEBUG) console.log(`skipping ${value.name} (item ${key})`) }
                });
                if (DEBUG) console.log("... kicked off all file promises")

                // this now updates the table and the UI
                await Promise.all(listOfFilePromises).then((_results) => {
                    // let's see what's in array buffers:
                    console.log("-------DONE building globalBufferMap ---------")
                    console.log(this.globalBufferMap)
                });

                this.#currentFileList.forEach((value) => {
                    if (value.name) {
                        let path = "/";
                        if (value.SBfullName) {
                            path = ("/" + value.SBfullName).substring(0, value.fullPath.lastIndexOf('/') + 1);
                        } else if (value.webkitRelativePath) {
                            path = ("/" + value.webkitRelativePath).substring(0, value.webkitRelativePath.lastIndexOf('/') + 1);
                        } else if (value.fullPath) {
                            path = value.fullPath.substring(0, value.fullPath.lastIndexOf('/') + 1);
                        } else if (nameToFullPath.has(value.name)) {
                            path = nameToFullPath.get(value.name)!.substring(0, nameToFullPath.get(value.name)!.lastIndexOf('/') + 1);
                        } else {
                            if (DEBUG2) {
                                console.log(`... no (further) path info for '${value.name}'`);
                                console.log(value);
                            }
                        }
                        // make sure last character is "/"
                        path = path.endsWith("/") ? path : path.concat("/");
                        if (DEBUG2) console.log(`... path for '${value.name}' is '${path}'`);
                        if (value.isDirectory === true) { value.type = "directory"; value.size = 0; }

                        let finalFullName = path + value.name;

                        let metaDataString = "";
                        let lastModifiedString = "";
                        if (value.lastModified) {
                            lastModifiedString = (new Date(value.lastModified)).toLocaleString();
                            metaDataString += ` [${lastModifiedString}]`;
                        }
                        if (value.size) {
                            metaDataString += ` [${value.size} bytes]`;
                        }
                        if (value.uniqueShardId) {
                            metaDataString += ` [${value.uniqueShardId.substr(0, 12)}]`;
                        }
                        finalFullName += metaDataString;

                        let row = {
                            name: value.name,
                            size: value.size,
                            type: value.type,
                            lastModified: lastModifiedString,
                            hash: value.uniqueShardId?.substr(0, 12),
                            // these are extra / hidden:
                            path: path,
                            uniqueShardId: value.uniqueShardId,
                            fullName: finalFullName,
                            metaDataString: metaDataString,
                            SBfullName: value.SBfullName
                        };

                        let currentRow = this.finalFileList.get(finalFullName);
                        if (!currentRow)
                            this.finalFileList.set(finalFullName, row);
                        else {
                            // just a handful of things worth overriding:
                            if (DEBUG) console.log(`... overriding some values for ${finalFullName} (this is rare)`)
                            if (currentRow!.size === undefined) currentRow!.size = row.size;
                            if (currentRow!.type === undefined) currentRow!.type = row.type;
                            if (currentRow!.lastModified === undefined) currentRow!.lastModified = row.lastModified;
                            if (currentRow!.uniqueShardId === undefined) currentRow!.uniqueShardId = row.uniqueShardId;
                        }

                        if (DEBUG2) { console.log(`File ${value.name} has info`); console.log(row); }
                    }
                });

                console.log("-------DONE building finalFileList ---------")
                console.log(this.finalFileList)

                // final coalesing;
                // we review the finalFileList, and remove directories, which includes everything
                // that we were unable to read the contents of
                if (SKIP_DIR) {
                    let reverseBufferMap: Map<string, Map<string, any>> = new Map(
                        Array.from(this.globalBufferMap.keys()).map((key) => [key, new Map()])
                    );
                    for (const key of this.finalFileList.keys()) {
                        let entry = this.finalFileList.get(key);
                        if ((entry!.type === "directory") || (entry.uniqueShardId === undefined)) {
                            if (DEBUG2) console.log(`... removing ${key} from final list (directory)`)
                            this.finalFileList.delete(key);
                        } else {
                            const uniqueShortName = entry.name + entry.metaDataString;
                            if (entry.path !== "/") {
                                const mapEntry = reverseBufferMap.get(entry.uniqueShardId)!.get(uniqueShortName);
                                if (mapEntry) {
                                    // we have a duplicate
                                    if (mapEntry.path.length > entry.path.length) {
                                        // we're the shorter one, so we remove ourselves
                                        this.finalFileList.delete(key);
                                    } else {
                                        // we're the longer one, so we remove the old guy
                                        this.finalFileList.delete(mapEntry.fullName);
                                        reverseBufferMap.get(entry.uniqueShardId)!.set(uniqueShortName, entry);
                                    }
                                } else {
                                    // otherwise we leave ourselves in
                                    reverseBufferMap.get(entry.uniqueShardId)!.set(uniqueShortName, entry);
                                }

                            }
                        }
                    }

                    if (DEBUG) console.log(reverseBufferMap)

                    // after that first pass, we can now see whether short names are unique
                    for (const key of this.finalFileList.keys()) {
                        let entry = this.finalFileList.get(key);
                        const uniqueShortName = entry.name + entry.metaDataString;
                        if (entry.path === "/") {
                            const mapEntry = reverseBufferMap.get(entry.uniqueShardId)!.get(uniqueShortName);
                            if (mapEntry) {
                                // we have a duplicate, and delete ourselves
                                if (DEBUG2) console.log(`... removing ${key} from final list (duplicate short name)`)
                                this.finalFileList.delete(key);
                            } else {
                                // otherwise we leave ourselves in
                                if (DEBUG2) console.log(`... leaving ${key} in final list (unique short name)`)
                            }
                        }
                    }

                }

                // finally we check if mime type is missing, and if so, try to figure it out
                for (const key of this.finalFileList.keys()) {
                    let entry = this.finalFileList.get(key);
                    if (entry.type === undefined) {
                        if (DEBUG2) console.log(`... trying to figure out mime type for ${key}`)
                        let mimeType = await getMimeType(entry.uniqueShardId);
                        if (mimeType) {
                            entry.type = mimeType;
                        } else {
                            entry.type = "";
                        }
                    }
                }

                // "export" as a sorted array to our table
                // let tableContents = Array.from(finalFileList).sort((a, b) => a[0].localeCompare(b[0]));
                // let tableContents = Array.from(finalFileList.values()).sort((a, b) => a.toString().localeCompare(b.toString()));
                let tableContents = Array.from(this.finalFileList.values()).sort((a, b) =>
                    a.path.localeCompare(b.path) || a.name.localeCompare(b.name)
                );

                if (DEBUG) {
                    console.log("Table contents:")
                    console.log(tableContents);
                }

                console.log("-------DONE with all file promises (clearing state) ---------")

                // now we clear for any additionl UI
                this.#globalItemNumber = createCounter();
                this.#globalFileItemNumber = createCounter();
                this.#globalFileMap = new Map();
                this.#currentFileList = new Map();
                // we do NOT clear the globalBufferMap

                if (callback) {
                    callback(tableContents);
                } else {
                    console.info("Note: no callback, so no update on tableContents:")
                    console.log(tableContents);
                }

            })(); // async
        }, 50);
    }





    //#region UI HOOKS ****************************************************************************************************
    //
    // Here's roughly how you would hook up from an HTML page to this code.
    // It will handle clicks and drops, both "file" and "directory" zones.
    //
    // "handleEvent()" handles all such events. It will call
    // scanItemList() and scanFileList() on all the data, then
    // the above "afteOperation()"

    // const fileDropZone = document.getElementById('fileDropZone');
    // const directoryDropZone = document.getElementById('directoryDropZone');
    // SBFileHelperReady.then(() => {
    //     fileDropZone.addEventListener('drop', SBFileHelper.handleFileDrop);
    //     directoryDropZone.addEventListener('drop', SBFileHelper.handleDirectoryDrop);

    //     fileDropZone.addEventListener('click', SBFileHelper.handleFileClick);
    //     directoryDropZone.addEventListener('click', SBFileHelper.handleDirectoryClick);
    // }

    handleFileDrop(event: DragEvent, callback: ((table: any[]) => void)) {
        event.preventDefault();
        return this.handleEvent(event, callback, "[file drop]");
    }

    handleDirectoryDrop(event: DragEvent, callback: ((table: any[]) => void)) {
        event.preventDefault();
        return this.handleEvent(event, callback, "[directory drop]");
    }

    handleFileClick(event: Event, callback: ((table: any[]) => void)) {
        event.preventDefault();
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.accept = '*/*';
        fileInput.addEventListener('change', (event) => {
            this.handleEvent(event, callback, "[file click]");
        });
        fileInput.click();
    }

    handleDirectoryClick(event: Event, callback: ((table: any[]) => void)) {
        event.preventDefault();
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.webkitdirectory = true;
        fileInput.accept = '*/*';
        fileInput.addEventListener('change', (event) => {
            this.handleEvent(event, callback, "[directory click]")
        });
        fileInput.click();
    }

    // this gets both input type=file and drag and drop
    async handleEvent(event: Event | DragEvent, callback: ((table: any[]) => void), _context: any) {
        let files, items;
        if ((event as DragEvent).dataTransfer) {
            files = (event as DragEvent).dataTransfer!.files;
            items = (event as DragEvent).dataTransfer!.items;
        } else if (event.target) {
            if ((event.target as any as CustomEventTarget).files)
                files = (event.target as any as CustomEventTarget).files;
            if ((event.target as any as CustomEventTarget).items)
                items = (event.target as any as CustomEventTarget).items;
        } else {
            console.log("Unknown event type (should not happen):");
            console.log(event);
            return;
        }
        if (DEBUG3) {
            console.log("Received items (DataTransferItemList):")
            console.log(items);
            console.log("Received files:")
            console.log(files);
        }
        this.scanItemList(items);
        this.scanFileList(files);
        this.afterOperation(callback);
    }

}