

import { FileHelper } from "./files";

export class FileTable {
    SBFileHelper: FileHelper;

    tableRows = new Map();
    table: Element; // = document.querySelector('#myTable tbody');
    // container: Element; // = document.querySelector(`#${location}`);
    expandAll: HTMLElement; // = document.getElementById("expandAll")
    collapseAll: HTMLElement; // = document.getElementById("collapseAll")
    uploadNewSetButton: HTMLElement; // = document.getElementById("uploadNewSetButton")
    tableFileInfo: HTMLElement; // = document.getElementById("table-file-info");
    hasChanges = false;

    knownShards: Map<any, any> // = new Map();

    rowClicked: ((metaData: any) => void) | null = null;
    previewFile: (path: string, hash: string, type: string) => void;
    findFileDetails: (hash: string) => any;

    constructor(
        SBFileHelper: FileHelper,
        table: Element,
        expandAll: HTMLElement,
        collapseAll: HTMLElement,
        uploadNewSetButton: HTMLElement,
        tableFileInfo: HTMLElement,
        knownShards: Map<any, any>,
        rowClicked: (metaData: any) => void | null,
        previewFile: (path: string, hash: string, type: string) => void,
        findFileDetails: (hash: string) => any
    ) {
        if (!SBFileHelper) throw new Error("SBFileHelper is null")
        this.SBFileHelper = SBFileHelper;

        if (!previewFile) throw new Error("previewFile is null")
        this.previewFile = previewFile;

        if (!findFileDetails) throw new Error("findFileDetails is null")
        this.findFileDetails = findFileDetails;

        if (!tableFileInfo) throw new Error("tableFileInfo is null")
        this.tableFileInfo = tableFileInfo;

        if (!uploadNewSetButton) throw new Error("uploadNewSetButton is null")
        this.uploadNewSetButton = uploadNewSetButton;

        if (!knownShards) throw new Error("knownShards is null")
        this.knownShards = knownShards;

        if (rowClicked) this.rowClicked = rowClicked;

        if (!table) throw new Error("table is null")
        if (!expandAll) throw new Error("expandAll is null")
        if (!collapseAll) throw new Error("collapseAll is null")

        this.table = table;
        this.expandAll = expandAll;
        this.collapseAll = collapseAll;
    }

    addRow(lexicalOrder: any, rowContents: any, metaData: any) {
        this.tableRows.set(lexicalOrder, { rowContents, metaData });

        // Sort map keys in lexical order
        this.tableRows = new Map([...this.tableRows.entries()].sort());

        // Iterate over sorted map and add rows to the table
        this.table.innerHTML = "";
        for (let [_key, value] of this.tableRows) {
            let row = document.createElement('tr');
            let cell = document.createElement('td');
            cell.textContent = value.rowContents;

            // Attach click handler with metaData
            if (this.rowClicked) {
                cell.addEventListener('click', () => {
                    this.expandAll.style.display = "flex";
                    this.collapseAll.style.display = "flex";
                    this.rowClicked!(value.metaData)
                });
            }

            row.appendChild(cell);
            this.table.appendChild(row);
        }
    }

    // note: 'editable' also doubles as 'omit' when null
    // first column is pretty much hard coded to expect a path
    renderTable(data: any[], headings: any[], editable: string | any[], location: any, onSave: any, actionButtons = true) {
        // console.log("Will render:")
        // console.log(data)
        let originalData = JSON.parse(JSON.stringify(data));
        let numberColumns = headings.length;
        if (numberColumns !== editable.length) {
            console.error("Number of headings and editable columns must match")
            return
        }
        let slatedForDeletion: any[] = [];
        const table = document.createElement("table");
        const thead = document.createElement("thead");
        const headingRow = document.createElement("tr");

        const saveBtn = document.createElement("button");
        const cancelBtn = document.createElement("button");

        const container = document.querySelector(`#${location}`)!;

        i = 0;
        let propertyNames: string[] = [];
        headings.forEach((heading: { label: string | null; key: any; }) => {
            if (editable[i++] !== null) {
                const headingCell = document.createElement("th");
                headingCell.textContent = heading.label;
                propertyNames.push(heading.key);
                headingRow.appendChild(headingCell);
            }
        });
        thead.appendChild(headingRow);
        table.appendChild(thead);

        const tbody = document.createElement("tbody");
        let lastPath = '';
        data.forEach((row: { [x: string]: string | null; path?: any; uniqueShardId?: any; type?: any; name?: any; }, index: any) => {
            // Count the number of slashes in the path
            const PATH_INDENT = 12;
            const depthPad = PATH_INDENT * (2 / 3) + (((row?.path?.match(/\//g) || []).length - 1)) * PATH_INDENT;


            if (row.path !== lastPath) {
                lastPath = row.path;
                const tableRow = document.createElement("tr");
                const tableData = document.createElement("td");
                tableData.colSpan = numberColumns;
                tableData.textContent = row.path;
                tableData.style.paddingLeft = depthPad + "px";
                tableRow.appendChild(tableData);
                tableRow.classList.add("folder");
                tableRow.dataset.name = row.path;
                tbody.appendChild(tableRow);
            }

            const tableRow = document.createElement("tr");
            tableRow.classList.add("file");
            tableRow.dataset.filePath = row.path;

            if (numberColumns > Object.keys(row).length) {
                // having extra (hidden) columns is fine
                console.error("Not enough columns in table for row: ", index)
                return
            }

            Object.keys(row).forEach((key, index) => {
                if (!propertyNames.includes(key))
                    return;

                if (editable[index] !== null) {
                    const tableData = document.createElement("td");
                    if (index == 0) {
                        tableData.style.paddingLeft = depthPad + PATH_INDENT + "px";
                    }
                    if (editable[index]) {
                        const input = document.createElement("input");
                        input.type = "text";
                        input.value = row[key]!;
                        input.addEventListener("input", () => {
                            row[key] = input.value;
                        });
                        tableData.appendChild(input);
                    } else {
                        if (editable[index] !== null) // null means skip
                            if ((key === "type") && (row[key] !== '')) {
                                tableData.dataset.hash = row.uniqueShardId;
                                tableData.dataset.type = row.type;
                                tableData.dataset.path = row.path;
                                tableData.dataset.name = row.name;
                                tableData.innerHTML += row[key].slice(0, 20) + " <span class='preview-file-icon'>🔍👀</span>";
                            } else {
                                tableData.textContent = row[key];
                            }
                    }
                    tableRow.appendChild(tableData);
                }
            });

            if (actionButtons) {
                const deleteButton = document.createElement("button");
                deleteButton.textContent = "Remove";
                deleteButton.addEventListener("click", () => {
                    // document.getElementById("uploadNewSetButton")!.setAttribute("disabled", "true");
                    this.uploadNewSetButton.setAttribute("disabled", "true");
                    tableRow.classList.add("slated-for-deletion");
                    this.hasChanges = true;
                    saveBtn.removeAttribute("disabled");
                    cancelBtn.removeAttribute("disabled");
                    deleteButton.setAttribute("disabled", "true");
                    console.log("slated for deletion: ", index)
                    slatedForDeletion.push(row.uniqueShardId)
                });
                const actionData = document.createElement("td");
                actionData.appendChild(deleteButton);
                tableRow.appendChild(actionData);
                if (tableRow.classList.contains("slated-for-deletion")) {
                    tableRow.classList.remove("slated-for-deletion");
                }
            }
            tbody.appendChild(tableRow);
        });
        table.appendChild(tbody);

        if (actionButtons) {
            saveBtn.setAttribute("id", "saveBtn")
            saveBtn.textContent = "Save";
            saveBtn.addEventListener("click", () => {
                // document.getElementById("uploadNewSetButton")!.removeAttribute("disabled");
                this.uploadNewSetButton.removeAttribute("disabled");
                // data = data.filter(row => !document.querySelector(`tr[data-index="${data.indexOf(row)}"]`).classList.contains("slated-for-deletion"));
                // data = data.filter(row => !row.classList.contains("slated-for-deletion"));
                console.log("hit save button. original:")
                console.log(originalData)
                data.forEach((item: { uniqueShardId: any; }, index: /* string | */ number) => {
                    if (slatedForDeletion.includes(item.uniqueShardId)) {
                        console.log(this.SBFileHelper)
                        console.log(this.SBFileHelper.finalFileList)
                        console.log(data[index])
                        this.SBFileHelper.finalFileList.delete(data[index].fullName)
                        this.SBFileHelper.globalBufferMap.delete(data[index].uniqueShardId)
                        console.log("deleting: ", index)
                        console.log(data[index])
                        delete data[index];
                    }
                });
                console.log("new:")
                console.log(data)
                // onSave(data);
                // originalData = JSON.parse(JSON.stringify(data));
                this.hasChanges = false;
                saveBtn.setAttribute("disabled", "true");
                cancelBtn.setAttribute("disabled", "true");

                this.renderTable(data, headings, editable, location, onSave);
            });
            table.appendChild(saveBtn);

            cancelBtn.setAttribute("id", "cancelBtn")
            cancelBtn.textContent = "Cancel";
            cancelBtn.addEventListener("click", () => {
                // document.getElementById("uploadNewSetButton")!.removeAttribute("disabled");
                this.uploadNewSetButton.removeAttribute("disabled");
                // data = JSON.parse(JSON.stringify(originalData));
                this.hasChanges = false;
                saveBtn.setAttribute("disabled", "true");
                cancelBtn.setAttribute("disabled", "true");
                console.log("hit cancel button. original:", data)
                this.renderTable(originalData, headings, editable, location, onSave);
            });

            if (this.hasChanges) {
                saveBtn.removeAttribute("disabled");
                cancelBtn.removeAttribute("disabled");
            } else {
                saveBtn.setAttribute("disabled", "true");
                cancelBtn.setAttribute("disabled", "true");
            }
            table.appendChild(cancelBtn);
        }
        container.innerHTML = "";
        container.appendChild(table);

        function toggleChildren(path: string) {
            var children = document.querySelectorAll<HTMLElement>('tr[data-file-path="' + path + '"]');
            for (var j = 0; j < children.length; j++) {
                children[j].style.display = (children[j].style.display == 'none') ? '' : 'none';
            }
        }

        var nameCells = document.querySelectorAll('tr.folder');
        // console.log(nameCells)
        for (var i = 0; i < nameCells.length; i++) {
            nameCells[i].addEventListener('click', function (this: HTMLTableRowElement) {
                // copilot: "this" referes to the event target for the event listener for the "click" event
                // how do i tell typescript what "this" is referring to?
                if (this.dataset.name) {
                    toggleChildren(this.dataset.name);
                } else {
                    console.error("this.dataset.name is null")
                }

                // var children = document.querySelectorAll('tr[data-file-path="' + this.dataset.name + '"]');
                // console.log("click on")
                // console.log(this)
                // console.log(this.dataset.name)
                // console.log("found these children")
                // console.log(children)
                // for (var j = 0; j < children.length; j++) {
                //     children[j].style.display = (children[j].style.display == 'none') ? '' : 'none';
                // }
            });
        }

        // add an event listener for "click" on any of the preview-file-icon elements
        // we want to pass it the "type" and "hash" that will be in the "data" attribute of the element
        // and then we want to call the "previewFile" function with those arguments
        // we can do this by using the "addEventListener" function

        document.querySelectorAll(".preview-file-icon").forEach((element) => {
            element.addEventListener("click", (event) => {
                if (!(event.target instanceof HTMLElement)) throw new Error("event.target is not an HTMLElement");
                const path = (<HTMLElement>event.target).parentElement?.dataset.path;
                const name = (<HTMLElement>event.target).parentElement?.dataset.name;
                const type = (<HTMLElement>event.target).parentElement?.dataset.type;
                const hash = (<HTMLElement>event.target).parentElement?.dataset.hash;
                const file = this.findFileDetails(hash!);
                console.log("file", file);
                if (!file) throw new Error("file not found in fileSetMap (?) ... new issue");
                // const tableFileInfo = document.getElementById("table-file-info");
                if (this.tableFileInfo) {
                    this.tableFileInfo.innerHTML = "";
                    const theader = document.createElement("thead");
                    const tbody = document.createElement("tbody");
                    const shard = /* web384.DataRoom. */ this.knownShards.get(hash);
                    const details = {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        lastModified: file.lastModified,
                        SBDetails: null as string | null,
                    }
                    if (shard) {
                        details.SBDetails = `${shard.id}.${shard.verification}`
                    }
                    for (const [key, value] of Object.entries(details)) {
                        const tr = document.createElement("tr");
                        const th = document.createElement("th");
                        th.textContent = key;
                        const td = document.createElement("td");
                        td.textContent = value;
                        tr.appendChild(th);
                        tr.appendChild(td);
                        tbody.appendChild(tr);
                    }
                    this.tableFileInfo.appendChild(theader);
                    this.tableFileInfo.appendChild(tbody);

                    this.previewFile(path! + name, hash!, type!);
                }

                // for (const [key, value] of Object.entries(details)) {
                //     const tr = document.createElement("tr");
                //     const th = document.createElement("th");
                //     th.textContent = key;
                //     const td = document.createElement("td");
                //     td.textContent = value;
                //     tr.appendChild(th);
                //     tr.appendChild(td);
                //     tbody.appendChild(tr);
                // }
                // tableFileInfo!.appendChild(theader);
                // tableFileInfo!.appendChild(tbody);
                // this.previewFile(path + name, hash, type);
            });
        });

    }
    
}
// window.renderTable = renderTable;

