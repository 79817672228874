import { SBServer } from "lib384";

interface Config extends SBServer {
  salt: ArrayBufferLike;
  iterations: number;
}

if(!process.env.REACT_APP_CHANNEL_SERVER) {
  throw new Error("REACT_APP_CHANNEL_SERVER is not defined");
}

if(!process.env.REACT_APP_CHANNEL_SERVER_WS) {
  throw new Error("REACT_APP_CHANNEL_SERVER_WS is not defined");
}

if(!process.env.REACT_APP_STORAGE_SERVER) {
  throw new Error("REACT_APP_STORAGE_SERVER is not defined");
}

if(!process.env.REACT_APP_SALT) {
  throw new Error("REACT_APP_SALT is not defined");
}

if(!process.env.REACT_APP_ITERATIONS) {
  throw new Error("REACT_APP_ITERATIONS is not defined");
}


export const config: Config = {
  channel_server: process.env.REACT_APP_CHANNEL_SERVER,
  channel_ws: process.env.REACT_APP_CHANNEL_SERVER_WS,
  storage_server: process.env.REACT_APP_STORAGE_SERVER,
  salt: new Uint8Array(JSON.parse(process.env.REACT_APP_SALT)),
  iterations: parseInt(process.env.REACT_APP_ITERATIONS),
};
