// (c) 2023 384 (tm)

import { encode, decode, generateStrongPin, generateStrongPin16, process } from './strongpin';

// ... replaced by actual font!
// import { strongpinFont } from './strongpin.font';
import { SBCrypto } from '../snackabra/snackabra';

import { getRandomValues } from '../snackabra/snackabra';

export const crypto = {
    strongpin : {
        encode,
        decode,
        generate: generateStrongPin,
        generate16: generateStrongPin16,
        process,
        // font: strongpinFont,
    },
    sbCrypto: new SBCrypto(),
    getRandomValues,
};
