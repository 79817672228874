// (c) 2023 384 (tm)

const DEBUG = false
export const strongpinVersion = "0.6.2";

/**
 * v05.05 (strongpinVersion 0.6.0)
 * 
 * 0123456789ADMRTxQjrEywcLBdHpNufk
 * 
 * (Current base62mi 'v05.05')
 */
export const base62mi05 = "0123456789ADMRTxQjrEywcLBdHpNufk" // "v05.05" (strongpinVersion 0.6.0)

// export const base62mi05 = "012345ABCDMPQRTVXJrEYWH8GLN7dkfu" // "v05.02"
// export const base62mi05 = "0123456789ADMQRTXJrEYWCPBdHLNukf" // "v05.03"
// export const base62mi05 = "0123456789ADMRTXQjrEyWCLBdHpNufk" // "v05.04" (strongpinVersion 0.5.6)

const base62 = base62mi05;
// const strictBase62Regex = new RegExp(`^[${base62}]{4}$`); // strict, in case we want to do that
const base62Regex = new RegExp(`[${base62}.concat(' ')]`); // lenient, allows spaces

export type StrongPinOptions = { extraEntropy: string, enforceMix: boolean, setCount: number }

// encodes a 19-bit number into a 4-character string
export function encode(num: number): string {
    const charMap = base62;
    if (num < 0 || num > 0x7ffff)
        throw new Error('Input number is out of range. Expected a 19-bit integer.');
    let bitsArr15 = [
        (num >> 14) & 0x1f,
        (num >> 9) & 0x1f,
        (num >> 4) & 0x1f,
        (num) & 0x0f
    ];
    bitsArr15[3] |= (bitsArr15[0] ^ bitsArr15[1] ^ bitsArr15[2]) & 0x10;
    return bitsArr15.map(val => charMap[val]).join('');
}

// generates a single 4-character set, does NOT enforce mix
async function _generateStrongPin(options?: StrongPinOptions): Promise<string> {
    const { extraEntropy } = options || {}
    let num, encoded, i = 0
    const hashArray = extraEntropy
    ? new Uint32Array(await crypto.subtle.digest('SHA-256', new TextEncoder().encode(extraEntropy)))
    : new Uint32Array([0]); // set to zero so has no effect
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    num = (array[0] ^ hashArray[0]) & 0x7FFFF; // xor in entropy, extract 19 bits
    encoded = encode(num);
    if (++i > 32)
        throw new Error('Unable to generate a strongpin after 32 attempts.')
    return encoded;
}

/**
 * crypto.generateStrongPinNN()
 * 
 * Generates a strongpin with "setCount" sets of 4-characters each.
 * (19 bits of entropy per set). In the case of "setCount" being 1,
 * this returns a type StrongPin, otherwise it returns a string.
 * 
 * Options:
 *  extraEntropy: string,
 *  enforceMix: boolean,
 *  setCount: number
 * 
 * ''enforceMix'' is a boolean that, if true, will ensure that the
 * generated strongpin has at least one of each: number, lowercase,
 * uppercase. With a single set, this will frequently cost one
 * or even two bits of entropy; with two sets, it will occasionally
 * cost one bit; with three sets, it will rarely cost one bit.
 * With four sets (the 'secure' setting), you lose less than
 * 1/100 of one bit of entropy (out of 76).
 * 
 */
export async function generateStrongPinNN(options?: StrongPinOptions): Promise<string> {
    let { enforceMix, setCount } = options || {}
    let res, i = 0
    if (!setCount) setCount = 1
    if (setCount < 1 || setCount > 40)
        // we can handle any length but if it's too long, it's probably a mistake
        throw new Error('setCount must be between 1 and 40 (upper limit is arbitrary).')

    // if "enforceMix" is true, then we iterate to ensure that the generated
    // strongpin has at least one of each: number, lowercase, uppercase
    do {
        res = (await Promise.all(Array(setCount).fill(null)
            .map(() => _generateStrongPin(options))))
            .join(' ');
        if (++i > 32)
            throw new Error('Unable to generate a strongpin16 after 32 attempts (should never happen even with singleton sets).')
    } while ((enforceMix) && (!(/[0-9]/.test(res) && /[a-z]/.test(res) && /[A-Z]/.test(res))));

    if (DEBUG && enforceMix) {
        if (i === 1) {
            console.log(`[generateStrongPinNN] got a proper strongping right away.`)
        } else {
            console.log(`[generateStrongPinNN] took ${i} iterations to generate a strongpin.`)
            console.log(`estimated (relative) entropy loss is ${(Math.log2(i) / (setCount * 19) * 100).toFixed(2)}%`)
        }
    }
    return res;
}

/**
 * crypto.generateStrongPin()
 * 
 * Generates a strongpin with A SINGLE set of 4-characters.
 * (19 bits of entropy).
 * 
 *  Convenience function.
 */
export async function generateStrongPin(options?: StrongPinOptions): Promise<string> {
    let options2 = { ...options, setCount: 1 } as StrongPinOptions
    return generateStrongPinNN(options2)
}

/**
 * crypto.generateStrongPin16()
 * 
 * Generates a strongpin with 4 sets of 4-characters each.
 * (19 bits of entropy per set, 76 bits total).
 * 
 * Convenience function.
 */
export async function generateStrongPin16(options?: StrongPinOptions): Promise<string> {
    let options2 = { ...options, setCount: 4 } as StrongPinOptions
    return generateStrongPinNN(options2)
}


/**
 * crypto.process()
 * 
 * does a "pre-processing", if there are substitions to be suggested,
 * it will perform them.  the callee should check if the returned
 * string has changed, in which case you should confirm with the user
 * something like 'did you mean to type this?'.  if the returned
 * string is the same as the input string, then there are no
 * substitutions to be made (unamgibuous).
 * 
 * The callee should enforce input matches ''/^[a-zA-Z0-9]*$/''.
 * 
 */
export function process(str: string): string {
    const substitutions: { [key: string]: string } = {
        // deliberately overly clear mapping
        "o": "0", "O": "0", "i": "1", "I": "1",
        "l": "1", "z": "2", "Z": "2", "s": "5",
        "S": "5", "b": "6", "G": "6", "a": "9",
        "g": "9", "q": "9", "m": "M", "t": "T",
        "X": "x", "J": "j", "e": "E", "Y": "y",
        "W": "w", "C": "c", "P": "p", "n": "N",
        "h": "N", "U": "u", "v": "u", "V": "u",
        "F": "f", "K": "k"
    }
    let processedStr = '';
    for (let char of str)
        processedStr += substitutions[char] || char;
    return processedStr;
}

/**
 * crypto.decode()
 * 
    will take a (correctly formed) 4-character string and return the
    original 19-bit number.  if the parity is incorrect, it will
    return null, meaning, one of the four characters were typed in
    incorrectly - for example, an "8" was entered that should be a "B".
    the callee should check for null and ask the user something like
    'are you sure about these four characters?'.
 */
export function decode(encoded: string): number | null {
    if (!base62Regex.test(encoded))
        throw new Error(`Input string contains invalid characters (${encoded}) - use 'process()'.`);
    let bin = Array.from(encoded)
        .map(c => base62.indexOf(c))
    if (bin.reduce((a, b) => (a ^ b)) & 0x10)
        return null;
    return (((bin[0] * 32 + bin[1]) * 32 + bin[2]) * 16 + (bin[3] & 0x0f));
}
